
import { useEffect, useState } from 'react';
import { firebase, AppFirebase } from '../../../service/firebase';
import * as C from './styles.js'

export default function Login() {

    const [ exibirSenha, setExibirSenha ] = useState();
    const [ email, setEmail ] = useState('');
    const [ senha, setSenha ] = useState('');

    useEffect(()=> {

        setExibirSenha(false);

        setTimeout(() => {
            document.getElementById("input-email").focus();
        }, 500);

    }, []);
    
    const loginEmail = async (e) => {
        e.preventDefault();
      
        try {

          const user = await firebase.auth().signInWithEmailAndPassword(email, senha);
          
        } catch (error) {
          alert('Erro durante o login, tente novamente');
        }
    };

    return (
      <C.Container>
        <C.Content>
          <h2>Fazer login</h2>
          <form method='' action='' onSubmit={loginEmail} className='form-login-cadastrar'>
              <fieldset>
                  <legend>Email</legend>
                  <input required id='input-email' value={email} type='email' onChange={(e)=>setEmail(e.target.value)}/>
              </fieldset>
              <fieldset className='fieldset-input-senha'>
                  <legend>Senha</legend>
                  <input required value={senha} type={exibirSenha ? 'text' : 'password'} onChange={(e)=>setSenha(e.target.value)}/>
              </fieldset>
              <div>
                  <button type='submit' className="bt-login-cadastrar"><i className='fa-solid fa-right-from-bracket'></i> Entrar</button>
              </div>
          </form>
        </C.Content>
      </C.Container>
    )

}