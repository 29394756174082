import React, { useEffect } from "react";
import * as C from "./styles";
import { Link } from "react-router-dom";
import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import { AppFirebase } from "../../../../../../service/firebase";

const Message = ({ user, message, profile, chatId }) => {

  const database = getFirestore(AppFirebase);

  function convertDate(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
  
    return `${padStart(day, 2)}/${padStart(month, 2)}/${year} às ${padStart(hour, 2)}:${padStart(minute, 2)}`;
  }
  
  function padStart(number, length) {
    return `0${number}`.slice(-length);
  }

  const deleteChat = async ()=> {
      const docRef = doc(database, `chats/${chatId}/messages/${message.id}`);
      const docSnap = await deleteDoc(docRef);
  }

  return (
    <C.Container>
      {message &&
        <C.Line className={((message?.profile_uid == 'admin' && message?.account_uid == 'admin') || message?.account_uid == user?.uid) ? "me" : (message?.tag == '#mensagem automática') ? 'bot' : ""}>
          <C.Content>
            {message?.img &&
              <a href={`https://dflix7.netlify.app${message?.url}`} target="_blank" className="redirect-img">
                <img src={message?.img}/>
                <span className="img-description"><i class="fa-solid fa-up-right-from-square"></i> Assistir agora</span>
              </a>
            }
            {message?.tag && 
              <C.Tag>{message?.tag} {message?.tag == '#mensagem automática' && <i class="fa-solid fa-robot"></i>}</C.Tag>
            }
            {(message?.message) &&
              <C.Message>{message?.message}</C.Message>
            }
            <C.MessageDate className={message?.user != 'admin' ? "other" : ""}>
              {convertDate(message?.date)}
            </C.MessageDate>
          </C.Content>
          <section>
              <button className='delete-message' onClick={deleteChat}><i className='fas fa-trash'></i></button>
          </section>
        </C.Line>
      }
    </C.Container>
  );
};

export default Message;
