import styled from "styled-components";

export const Container = styled.div `

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100vw;
    border-radius: 10px;
    padding: 10px;
    transition: all .3s ease;

`

export const Content = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 250px;
    max-width: 250px;

    h2 {
        margin-bottom: 20px;
        font-size: 2rem;
    }

    .form-login-cadastrar {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .form-login-cadastrar fieldset {
        position: relative;
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .form-login-cadastrar legend, .form-login-cadastrar legend  {
        margin-left: 5px;
        padding: 0 5px 0 5px;
        font-weight: 600;
    }

    .form-login-cadastrar input[type=text], .form-login-cadastrar input[type=email], .form-login-cadastrar input[type=password] {
        padding: 5px 5px 10px 5px;
        width: 100%;
        background: none;
        color: #fff;
        border: none;
        outline: none;
        font-size: .9rem;
    }

    .form-login-cadastrar textarea {
        padding: 5px 5px 10px 5px;
        min-width: 250px;
        max-width: 250px;
        min-height: 50px;
        max-height: 300px;
        background: none;
        color: #fff;
        border: none;
        outline: none;
        font-size: .9rem;
    }

    .fieldset-input-senha input {
        padding-right: 35px;
    }

    button {
        padding: 10px 20px;
        border-radius: 5px;
        color: #fff;
        background: #f00;
        border: none;
        cursor: pointer;
        font-size: .9rem;
        font-weight: 600;
    }
`