import styled from "styled-components";

export const Container = styled.div `
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background: #101010;
    overflow: hidden;
    z-index: 100;
    
    .default {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: auto;
    }
    
    .default .icon-message {
        font-size: 5rem;
        margin-bottom: 20px;
    }
    
    .default h2 {
        font-size: 1.3rem;
    }
    
    .default .p-default {
        font-size: .9rem;
        font-weight: 600;
    }
    
    @media (max-width: 800px) {
        .default {
            display: 'block';
        }
    }
    
`
export const Content = styled.div `
    display: flex;
    height: calc(100% - 70px);

    .div-default-chat-desktop {
        display: flex;
        width: 100%;
    }

    @media (max-width: 800px) {
        .div-default-chat-desktop {
            display: none;
        }
    }
`