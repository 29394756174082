import { useEffect, useState } from "react";
import * as C from "./styles.js"
import { AppFirebase } from '../../service/firebase.js';
import { collection, deleteDoc, doc, getFirestore, onSnapshot, orderBy, query, updateDoc } from "firebase/firestore";

export default function Requests({ requests, setShowMovie }) {
    const [copied, setCopied] = useState(false);
    const [currentView, setCurrentView] = useState('active');
    const database = getFirestore(AppFirebase);

    const copyToClipboard = (text, type) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopied(type);

                setTimeout(() => {
                    setCopied(false);
                }, 5000);
            })
            .catch((error) => {
                console.error('Erro ao copiar texto para a área de transferência:', error);
            });
    };

    function timestampToDate(timestamp) {
        const date = new Date(timestamp * 1000);
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return date.toLocaleDateString('pt-BR', options);
    }

    const deleteRequest = async (id) => {
        const userConfirmed = window.confirm("Tem certeza que deseja excluir este request?");
        if (userConfirmed) {
            try {
                await deleteDoc(doc(database, `request_movies/${id}`));
            } catch (error) {
                console.error("Erro ao excluir request:", error);
                alert("Ocorreu um erro ao excluir o request.");
            }
        }
    };

    const changeStatus = async (id, status) => {
        const userConfirmed = window.confirm("Tem certeza que deseja alterar o status deste request?");
        if (userConfirmed) {
            try {
                await updateDoc(doc(database, `request_movies/${id}`), {
                    status: status,
                    admin_message: "Indisponível"
                });
            } catch (error) {
                console.error("Erro ao alterar o status do request:", error);
                alert("Ocorreu um erro ao alterar o status do request.");
            }
        }
    };

    const filteredRequests = () => {
        switch (currentView) {
            case 'all':
                return requests?.requests?.items;
            case 'finished':
                return requests?.requests_finished?.items;
            case 'later':
                return requests?.requests_later?.items;
            case 'active':
            default:
                return requests?.requests_active?.items;
        }
    };

    return (
        <C.Container>
            <h1>Pedidos ativos: {requests?.requests_active?.length} | Finalizados: {requests?.requests_finished?.length} | Em espera: {requests?.requests_later?.length} | Total: {requests?.requests?.length}</h1>
            <C.Request>
                <div className="options">
                    <button className={currentView == 'all' ? 'active' : null} onClick={() => setCurrentView('all')}>Todos</button>
                    <button className={currentView == 'active' ? 'active' : null} onClick={() => setCurrentView('active')}>Pendentes</button>
                    <button className={currentView == 'finished' ? 'active' : null} onClick={() => setCurrentView('finished')}>Finalizados</button>
                    <button className={currentView == 'later' ? 'active' : null} onClick={() => setCurrentView('later')}>Mais tarde</button>
                </div>
            </C.Request>
            {copied &&
                <span className="span-copied">Copiado: {copied}</span>
            }
            <ul>
                {filteredRequests()?.map((request, key) => (
                    <C.Request key={key} status={request.status ? request.status : false}>
                        <span className="status">Status: {request.status ? request.status : "Pendente"}</span>
                        <div className="user">
                            <h3>Nome: {request?.account_name}</h3>
                            <p>Perfil: {request?.profile_name}</p>
                            <p>Email: {request?.account_email}</p>
                        </div>
                        <p>{request?.movie_requested_id ? "Filme: " : 'Série: '}{request?.tv_requested_name ? request?.tv_requested_name : request?.movie_requested_name}</p>
                        <p>{request?.id}</p>
                        {request?.tv_requested_id &&
                            <>
                                <p>Temporada: {request?.tv_requested_season}</p>
                                <p>Episódio: {request?.tv_requested_episode}</p>
                            </>
                        }
                        {request?.date &&
                            <p className="date">{timestampToDate(request?.date?.seconds)}</p>
                        }
                        <div className="options">
                            {/*
                                <button onClick={() => copyToClipboard(request?.account_uid, "id conta")}>Copiar Id (Conta)</button>
                                <button onClick={() => copyToClipboard(request?.profile_uid, "id perfil")}>Copiar Id (Profile)</button>
                                <button onClick={() => copyToClipboard(request?.id, "id TMDB")}>Copiar TMDB</button>
                             */}
                            <button onClick={() => setShowMovie(`https://www.themoviedb.org/${request?.movie_requested_id ? "movie" : "tv"}/${request?.movie_requested_id ? request?.movie_requested_id : request?.tv_requested_id}/images/backdrops?image_language=pt&language=pt-BR`)}>Ir para {request?.movie_requested_id ? "o filme" : 'a série'}</button>
                        </div>
                        <div className="options">
                            <button onClick={() => copyToClipboard(request?.id)}>Copiar id (pedido)</button>
                            <button onClick={() => deleteRequest(request?.id)}>Excluir</button>
                            <button onClick={() => changeStatus(request?.id, 'finished')}>Finalizar pedido</button>
                            <button onClick={() => changeStatus(request?.id, 'later')}>"Mais tarde"</button>
                        </div>
                    </C.Request>
                ))}
            </ul>
        </C.Container>
    )
}