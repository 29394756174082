import { useState } from 'react';
import * as C from './styles.js';
import { AppFirebase } from '../../../../../service/firebase.js';
import { doc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

export default function NewChat( { setNewChat, account_uid, profile_uid } ) {

    const database = getFirestore(AppFirebase);
    
    const uuid = require('uuid');
    const navigate = useNavigate();

    const [ formNewChat, setFormNewChat ] = useState('chat');

    const [ requestedId, setRequesteId ] = useState('');

    const [ profileUid, setProfileUid ] = useState('');
    const [ accountUid, setAccountUid ] = useState('');
    const [ title, setTitle ] = useState('');
    const [ subtitle, setSubtitle ] = useState('');
    const [ img, setImg ] = useState('dflix');

    const [ message, setMessage ] = useState('');
    const [ imgMessage, setImgMessage ] = useState('');
    const [ url, setUrl ] = useState('');

    const newChat = async () => {
        const chatId = uuid.v4();
    
        await setDoc(doc(database, "chats", chatId), {
            title: title,
            subtitle: subtitle,
            profile_uid: profileUid,
            account_uid: accountUid,
            img: img,
            id: chatId,
            date: serverTimestamp(),
            updated: serverTimestamp(),
            new_message: true,
            new_message_by: 'admin',
            tag: '#mensagem automática',
            requested_id: requestedId,
            finished: false
        }).then(() => {
            newMessage(chatId);
        });
    }
    
    const newMessage = async (chatId) => {
        const messageId = uuid.v4();
    
        await setDoc(doc(database, "chats", chatId, 'messages', messageId), {
            message: message,
            image: imgMessage,
            url: url,
            date: serverTimestamp(),
            account_uid: account_uid,
            profile_uid: profile_uid,
        }).then(() => {
            navigate(`/chat/${chatId}`);
            setNewChat(false)
            alert('Chat e mensagem adicionados');
        });
    }

    return(

        <C.Container>
            <C.Content>
                <header>
                    <button className='close' onClick={()=>setNewChat(false)}><i className="fas fa-xmark"></i></button>
                    <div>{formNewChat == 'chat' ? 'Novo chat' : 'Nova mensagem'}</div>
                </header>
                {formNewChat == 'chat' &&
                    <>
                        <div className='form'>
                            <input type='text' onChange={(e)=>setProfileUid(e.target.value)} value={profileUid} placeholder='Profile uid'/>
                            <input type='text' onChange={(e)=>setAccountUid(e.target.value)} value={accountUid} placeholder='Account uid'/>
                            <input type='text' onChange={(e)=>setTitle(e.target.value)} value={title} placeholder='Title'/>
                            <input type='text' onChange={(e)=>setSubtitle(e.target.value)} value={subtitle} placeholder='Subtitle'/>
                            <input type='text' onChange={(e)=>setRequesteId(e.target.value)} value={requestedId} placeholder='Request id'/>
                            <input type='text' onChange={(e)=>setImg(e.target.value)} value={img} placeholder='img'/>
                        </div>
                        <button className='send' onClick={()=>setFormNewChat('message')}>Próximo</button>
                    </>
                }
                {formNewChat == 'message' &&
                    <>
                        <div className='form'>
                            <input type='text' onChange={(e)=>setMessage(e.target.value)} value={message} placeholder='Message'/>
                            <input type='text' onChange={(e)=>setImgMessage(e.target.value)} value={imgMessage} placeholder='img'/>
                            <input type='text' onChange={(e)=>setUrl(e.target.value)} value={url} placeholder='url'/>
                        </div>
                        <button className='send' onClick={()=>setFormNewChat('chat')}>Voltar</button>
                        <button className='send' onClick={newChat}>Enviar</button>
                    </>
                }
            </C.Content>
        </C.Container>
    )
}