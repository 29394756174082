import { useState } from 'react';
import * as C from './styles.js';
import { AppFirebase } from '../../../../../service/firebase.js';
import { doc, getDoc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

export default function NewChatAuthomatized( { setNewChatAuthomatized } ) {
    
    const uuid = require('uuid');

    const navigate = useNavigate();

    const database = getFirestore(AppFirebase);
    
    const [ requestedId, setRequesteId ] = useState('');

    const newChat = async () => {
        
        const chatId = uuid.v4();

        const docRef = doc(database, `request_movies/${requestedId}`);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {

            const data = docSnap.data();
            console.log(data)
            
            await setDoc(doc(database, "chats", chatId), {
                title: `Pedido ${data?.tv_requested_type ? 'série' : 'filme'} "${data.movie_requested_name ? data.movie_requested_name : data.tv_requested_name}"`,
                subtitle: `${data.movie_requested_name ? data.movie_requested_name : data.tv_requested_name} foi adicionado`,
                profile_uid: data.profile_uid,
                account_uid: data.account_uid,
                img: 'dflix',
                id: chatId,
                request_id: requestedId,
                date: serverTimestamp(),
                updated: serverTimestamp(),
                new_message: true,
                new_message_by: 'admin',
                finished: false
            }).then(()=>{
                navigate(`/chat/${chatId}`);
                setNewChatAuthomatized(false);
            })

        } else {
            console.log("O Documento não existe")
        }
    }

    return(

        <C.Container>
            <C.Content>
                <header>
                    <button className='close' onClick={()=>setNewChatAuthomatized(false)}><i className="fas fa-xmark"></i></button>
                    <div>Novo chat</div>
                </header>
                <div className='form'>
                    <input type='text' onChange={(e)=>setRequesteId(e.target.value)} value={requestedId} placeholder='Request id'/>
                </div>
                <button className='send' onClick={newChat}>Criar chat</button>
            </C.Content>
        </C.Container>
    )
}