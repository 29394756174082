import styled from "styled-components";

export const Container = styled.div `
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 95vh;
    overflow: auto;
    padding-bottom: 50px;
`

export const Content = styled.div `
    margin-top: 0;
`

export const Header = styled.header `
    display: flex;
    overflow-x: auto;
    margin-bottom: 20px;

    &::-webkit-scrollbar {
        display: none;
    }
`

export const Button = styled.button `
    padding: 8px 15px;
    border-radius: 50px;
    background: ${props => props.active ? '#fff' : 'unset'};
    border: 2px solid #fff;
    color: ${props => props.active ? '#181818' : '#fff'};
    cursor: pointer;
    margin-right: 10px;
    font-weight: 600;
    transition: all .3s ease;
    white-space: nowrap;
`

export const Subtitle = styled.h3 `
    margin-bottom: 10px;
`

export const Input = styled.input `
    height: 40px;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 5px;
    border: none;

    &::placeholder {
        color: #909090;
        font-weight: 600;
        text-transform: capitalize;
    }
`

export const Send = styled.button `
    padding: 8px 15px;
    border-radius: 50px;
    background: unset;
    border: 2px solid #fff;
    color: #fff;
    cursor: pointer;
    margin-top: 10px;
    font-weight: 600;
    transition: all .3s ease;

    &:hover {
        background: #f00;
    border: 2px solid #f00;
    }
`

export const Form = styled.form `
    display: flex;
    flex-direction: column;
`