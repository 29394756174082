import styled from "styled-components";

export const Container = styled.header`

    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #252525;
    border-radius: 5px;
    padding: 15px 50px 15px 15px;
    border-bottom: 2px solid #505050;
    height: 70px;

    section {
        display: flex;
    }

    .back {
        background: #404040;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        font-weight: 700;
        color: #fff;
        margin-right: 20px;
        transition: all .3s ease;
    }

    .back i {
        margin-right: 10px;
    }

    .back:hover {
        background: #505050;
    }
    
    .logo {
        height: 35px;
    }

    
    @media (max-width: 400px) {
        
        padding: 15px 10px 15px 10px;

        .back {
            padding: 8px 15px;
            font-size: .8rem;
            margin-right: 10px;
        }

        .back i {
            margin-right: 5px;
        }

        .logo {
            height: 30px;
        }
    }

`