import { doc, getFirestore, setDoc, serverTimestamp, getDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react"
import { AppFirebase } from "../../service/firebase.js";
import Usuario from "../usuario/index.js";

import * as C from './styles.js'
import { APIKey } from "../../config/key.js";

export default function AddNotification() {

    //add notificacao
    const [ idNotification, setIdNotification ] = useState();
    const [ idMovieNotification, setIdMovieNotification ] = useState();
    const [ nomeNotification, setNomeNotification ] = useState();
    const [ temporadaNotification, setTemporadaNotification ] = useState('° Temporada');
    const [ dataNotification, setDataNotification ] = useState();
    const [ tipoNotification, setTipoNotification ] = useState('tv');
    const [ imgNotification, setImgNotification ] = useState();
    const [ urlNotification, setUrlNotification ] = useState();

    useEffect(()=> {
        document.querySelector("meta[name=robots]").setAttribute("content", 'noindex,nofollow');
    }, [ ])

    useEffect(()=> {
        if (tipoNotification == 'tv') {
            setTemporadaNotification('° Temporada');
        } else {
            setTemporadaNotification('Filme');
        }
    }, [ tipoNotification ])


    const database = getFirestore(AppFirebase);
    const addNotification = async (e) => {
        e.preventDefault();
        if (idMovieNotification) {
            await setDoc(doc(database, "notifications", idNotification), {
                name: nomeNotification,
                temporada: temporadaNotification,
                img: imgNotification,
                data: dataNotification,
                tipo: tipoNotification,
                idMovie: idMovieNotification,
                timestamp: converterTimestamp(dataNotification)
            }).then(()=>{
                alert("notificação adicionada");
                setIdNotification('')
                setNomeNotification('');
                setDataNotification('');
                setIdMovieNotification('');
                setImgNotification('');
                setTipoNotification('');
                setTemporadaNotification('° Temporada');
            }).catch((erro)=>{
                alert(erro)
            });
        } else {
            await setDoc(doc(database, "notifications", idNotification), {
                name: nomeNotification,
                img: imgNotification,
                data: dataNotification,
                urlNotification: urlNotification,
                timestamp: converterTimestamp(dataNotification)
            }).then(()=>{
                alert("notificação adicionada");
                setIdNotification('')
                setNomeNotification('');
                setDataNotification('');
                setImgNotification('');
                setUrlNotification('');
            }).catch((erro)=>{
                alert(erro)
            });
        }
        
    }
    
    function converterTimestamp(e) {
        var dataHoje = e.slice(6, 11) + '-' + e.slice(3, 5) + "-" + e.slice(0, 2);
        var dataHojeTimestamp = new Date(dataHoje + " 04:00:00");
        const timestampDataHoje = Math.floor(dataHojeTimestamp.getTime());
        return timestampDataHoje;
    }

    return (
        <>
            <C.Container>
                <C.Form method="" action="" onSubmit={addNotification}>
                    <C.Header>
                        <C.Button active={tipoNotification == 'tv'} onClick={()=>setTipoNotification('tv')}>Série</C.Button>
                        <C.Button active={tipoNotification == 'movie'} onClick={()=>setTipoNotification('movie')}>Filme</C.Button>
                    </C.Header>
                    <C.Input required type='text' placeholder="id Notificação" value={idNotification} onChange={(e)=>setIdNotification(e.target.value)}/>
                    <C.Input required type='text' placeholder="nome" value={nomeNotification} onChange={(e)=>setNomeNotification(e.target.value)}/>
                    <C.Input required type='text' placeholder="temporada" value={temporadaNotification} onChange={(e)=>setTemporadaNotification(e.target.value)}/>
                    <C.Input required type='text' placeholder="data" value={dataNotification} onChange={(e)=>setDataNotification(e.target.value)}/>
                    <C.Input required type='text' placeholder="id Movie" value={idMovieNotification} onChange={(e)=>setIdMovieNotification(e.target.value)}/>
                    <C.Input required type='text' placeholder="img" value={imgNotification} onChange={(e)=>setImgNotification(e.target.value)}/>
                    <C.Input required type='text' placeholder="tipo" value={tipoNotification} onChange={(e)=>setTipoNotification(e.target.value)}/>
                    <C.Input required type='text' placeholder="url" value={urlNotification} onChange={(e)=>setUrlNotification(e.target.value)}/>
                    <C.Send>Enviar</C.Send>
                </C.Form>
            </C.Container>
        </>
    )
}