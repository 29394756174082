import { doc, getFirestore, setDoc, serverTimestamp, collection, onSnapshot, query } from "firebase/firestore";
import { useEffect, useRef, useState } from "react"
import { AppFirebase } from "../../service/firebase";
import Usuario from "../usuario";

import * as C from './styles.js'
import { APIKey } from "../../config/key.js";

export default function AddTv() {

    const inputUrl = useRef();

    // add movie
    const [ name, setName ] = useState();
    const [ description, setDescription ] = useState();
    const [ season, setSeason ] = useState(1);
    const [ episode, setEpisode ] = useState(1);
    const [ url, setUrl ] = useState();
    const [ movieType, setMovieType ] = useState('movie');
    const [ id, setId ] = useState();
    const [ ageRating, setAgeRating ] = useState();
    const [ ageRatingDescription,setAgeRatingDescription ] = useState();
    const [ logoPath,setLogoPath ] = useState();
    const [ backdropPath,setBackdropPath ] = useState();
    const [ nextEpisode, setNextEpisode ] = useState(null);
    const [ startOpening, setStartOpening ] = useState(null);
    const [ endOpening, setEndOpening ] = useState(null);
    const [ codeTv, setCodeTv ] = useState(null);

    useEffect(()=> {
        document.querySelector("meta[name=robots]").setAttribute("content", 'noindex,nofollow');
    }, [ ]);

    
    
    useEffect(()=>{

        const chatsRef = query(
            collection(database, `tv`)
          );
          const getCodesTv = onSnapshot(chatsRef, (snapshot) => {
            const updatedDocuments = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            console.log(updatedDocuments)
            setCodeTv(updatedDocuments.length + 1);
          }, (error) => {
            console.error('Ocorreu um erro ao solicitar chats:', error);
          });
          return () => getCodesTv();
    }, [ ]);
    
    function formatNumber(number) {
        // Converte o número para uma string
        let numberToString = number.toString();
        
        // Adiciona zeros à esquerda até alcançar 7 dígitos
        while (numberToString.length < 7) {
            numberToString = '0' + numberToString;
        }
        
        // Retorna o número formatado
        return numberToString;
    }

    async function submit(e) {

        e.preventDefault();

        const database = getFirestore(AppFirebase);
        if (movieType == 'tv') {
            await setDoc(doc(database, `tv/${formatNumber(codeTv)}`), {
                name: name,
                description: description,
                player: 'dflix',
                src: url,
                age_rating: ageRating,
                age_rating_description: ageRatingDescription,
                show_next: nextEpisode,
                end_opening: endOpening,
                start_opening: startOpening,
                backdrop_path: backdropPath,
                logo_path: logoPath,
                season: season,
                episode: episode,
                media_type: 'tv',
                date: serverTimestamp(),
                code_tv: formatNumber(codeTv)
            }).then(()=> {
                alert("adicionado");
            }).catch((erro)=>{
                alert(erro)
            })
        } else {
            await setDoc(doc(database, `tv/${formatNumber(codeTv)}`), {
                name: name,
                description: description,
                player: 'dflix',
                src: url,
                age_rating: ageRating,
                age_rating_description: ageRatingDescription,
                backdrop_path: backdropPath,
                logo_path: logoPath,
                code_tv: formatNumber(codeTv),
                date: serverTimestamp(),
                media_type: 'movie',
            }).then(()=>{
                alert("filme adicionado")
            })
        }
    }

    const database = getFirestore(AppFirebase);

    function getMovie(e) {
        e.preventDefault();
        fetch(`https://api.themoviedb.org/3/${movieType}/${id}?api_key=${APIKey}&vote_count.gte=10&language=pt-BR`)
        .then(Response => Response.json())
        .then(data => {
            setName(data.title ? data.title : data.name);
            setDescription(data.overview)
        });

        if (movieType == 'tv') {
            fetch(`https://api.themoviedb.org/3/tv/${id}/content_ratings?api_key=${APIKey}`)
            .then(Response => Response.json())
            .then(data => {
                data.results.map((rating)=>{
                    if(rating.iso_3166_1 == "BR") {
                        setAgeRating(rating.rating)
                    }
                })
            });
        } else {
            fetch(`https://api.themoviedb.org/3/movie/${id}/release_dates?api_key=${APIKey}`)
            .then(Response => Response.json())
            .then(data => {
    
                data.results.map((certification)=>{
                    if(certification.iso_3166_1 == "BR" ) {
                        setAgeRating(certification.release_dates[0].certification);
                    }
                })
            });
        }
    }

    return (
        <>
            <C.Container>
                <C.Content>
                    <C.Header>
                        <C.Button active={movieType == 'movie'} onClick={()=>setMovieType('movie')}>Filme</C.Button>
                        <C.Button active={movieType == 'tv'} onClick={()=>setMovieType('tv')}>Série</C.Button>
                    </C.Header>
                    <C.Form method="" action="" onSubmit={submit}>
                        <div>
                            <C.Input required type='text' placeholder="id" value={id} onChange={(e)=>setId(e.target.value)}/>
                            <C.Button onClick={getMovie}><i className="fas fa-search"></i></C.Button>
                        </div>
                        <C.Input required type='text' placeholder="Nome" value={name} onChange={(e)=>setName(e.target.value)}/>
                        <C.Input required type='text' placeholder="Descrição" value={description} onChange={(e)=>setName(e.target.value)}/>
                        <C.Input required type='text' placeholder="Classificação indicativa" value={ageRating} onChange={(e)=>setAgeRating(e.target.value)}/>
                        <C.Input required type='text' placeholder="Descrição da classificação indicativa" onChange={(e)=>setAgeRatingDescription(e.target.value)}/>
                        <C.Input required type='text' placeholder="Imagem de fundo" onChange={(e)=>setBackdropPath(e.target.value)}/>
                        <C.Input required type='text' placeholder="Logo path" onChange={(e)=>setLogoPath(e.target.value)}/>
                        <C.Input required type='text' placeholder="Código tv" value={codeTv} onChange={(e)=>setCodeTv(e.target.value)}/>
                        {movieType == 'tv' &&
                            <>
                                <C.Input required type='text' placeholder="Inicio da abertura (em segundos)" value={startOpening} onChange={(e)=>setStartOpening(e.target.value)}/>
                                <C.Input required type='text' placeholder="Fim da abertura (em segundos)" value={endOpening} onChange={(e)=>setEndOpening(e.target.value)}/>
                                <C.Input required type='text' placeholder="Exibir botão próximo episódio (em segundos)" value={nextEpisode} onChange={(e)=>setNextEpisode(e.target.value)}/>
                                <C.Input required type='text' placeholder="Temporada" value={season} onChange={(e)=>setSeason(e.target.value)}/>
                                <C.Input required type='text' placeholder="Episodio" value={episode} onChange={(e)=>setEpisode(e.target.value)}/>
                            </>
                        }
                        <C.Input required ref={inputUrl} type='text' placeholder="Src" value={url} onChange={(e)=>setUrl(e.target.value)}/>
                        <C.Send>Enviar</C.Send>
                    </C.Form>
                </C.Content>
            </C.Container>
        </>
    )
}