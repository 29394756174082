import { useParams } from "react-router-dom";
import Dashboard from "../../componentes/dashboard/index.js"
import * as C from "./styles.js";
import AddMovie from "../add-movie/index.js";
import Requests from "../request/index.js";
import GetRequests from "./componentes/get-requests/index.js";
import { useState } from "react";
import Usuario from "../usuario/index.js";
import AddNotification from "../add-notifications/index.js";
import AddMember from "../add-member/index.js";
import Login from "../usuario/login/index.js";
import Chat from "../chat/index.js";
import AddTv from "../add-tv/index.js";
import AddMovieApp from "../add-movie-app/index.js";

export default function Home() {

    const { screen } = useParams();

    const [ requests, setRequests ] = useState();

    // get and verify is member to access the dashboard
    const [ memberInfo, setMemberInfo ] = useState({});
    const [ loadingMember, setLoadingMember ] = useState(true);

    const [ user, setUser ] = useState();

    const [ showMovie, setShowMovie ] = useState(null)

    return(
        <C.Container>
            <Usuario setUser={setUser} setMemberInfo={setMemberInfo} getMemberInfo={true} setLoadingMember={setLoadingMember}/>
            {(user?.uid && !loadingMember && memberInfo?.account_type === 'admin') ?
                <C.Content>
                        <GetRequests setRequests={setRequests}/>
                    <Dashboard vertical={screen} requests={requests} user={user} screen={screen}/>
                    {screen &&
                        <C.Screens>
                            {screen == 'requests' && <Requests requests={requests} setShowMovie={setShowMovie}/>}
                            {screen == 'movie' && <AddMovie/>}
                            {screen == 'movieApp' && <AddMovieApp/>}
                            {screen == 'notification' && <AddNotification/>}
                            {screen == 'member' && <AddMember/>}
                            {screen == 'chat' && <Chat user={user}/>}
                            {screen == 'tv' && <AddTv/>}
                        </C.Screens>
                    }
                    {showMovie &&
                        <div className="container-iframe">
                            <div>
                                <button onClick={()=>setShowMovie(null)}><i className="fas fa-xmark"></i></button>
                                <button onClick={()=>window.open(showMovie, "_blank")}><i className="fa-solid fa-arrow-up-right-from-square"></i></button>
                            </div>
                            <iframe src={showMovie}></iframe>
                        </div>
                    }
                </C.Content>
                :
                <div>
                    <Login/>
                </div>
            }
        </C.Container>
    )
}