import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  background: unset;
  height: ${props => props.admin ? 'calc(100% - 170px)' : 'calc(100% - 130px)'};

  .section-loading-chat {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .authomatized-messages-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #00efff45;
    padding: 30px;
    margin: 30px 50px;
    border-radius: 5px;
  }

  .authomatized-messages-container h2 {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 1.5rem;
  }

  .authomatized-messages-container .form {
    display: flex;
    justify-content: center;
    width: 95%;
    margin-bottom: 10px;
  }

  .authomatized-messages-container .form input {
    width: 100%;
    margin-right: 10px;
    height: 40px;
    padding: 10px;
    background: #fff;
    border: none;
    max-width: 750px;
  }

  .authomatized-messages-container .form button {
    border-radius: 50px;
    width: 40px;
    height: 40px;
    background: #fff;
    cursor: pointer;
    border: none;
  }

  .authomatized-messages-container .form button:hover {
    background: #ccc;
  }

  &::-webkit-scrollbar-track{
    background-color: #181818;
  }

  @media (max-width: 600px) {
    height: ${props => props.admin ? 'calc(100% - 195px)' : 'calc(100% - 110px)'};
  }

  @media (max-width: 500px) {
    height: ${props => props.admin ? 'calc(100% - 180px)' : 'calc(100% - 105px)'};
  }
`;