import React, { useState, useEffect, useRef } from "react";
import * as C from "./styles";
import { MdSend } from "react-icons/md";
import { addDoc, collection, doc, getFirestore, serverTimestamp, updateDoc } from "firebase/firestore";
import { AppFirebase } from "../../../../../service/firebase";
import { FaLock } from "react-icons/fa";

const ChatFooter = ({ chatId, chat_finished }) => {
  const [message, setMessage] = useState("");
  const [imagem, setImagem] = useState("");
  const [url, setUrl] = useState("");
  
  const database = getFirestore(AppFirebase);

  const sendNewMessage = async () => {
    const messageData = {
      message: message,
      date: serverTimestamp(),
      profile_uid: 'admin',
      account_uid: 'admin',
    };

    if (imagem) {
      messageData.img = imagem;
      messageData.url = url;
    }

    const messagesRef = collection(database, "chats", chatId, "messages");
    await addDoc(messagesRef, messageData);
    handleNewMessage();
  };

  const handleNewMessage = async () => {
    const messageData = {
      new_message: true,
      new_message_by: 'admin',
      updated: serverTimestamp(),
    };

    const messagesRef = doc(database, "chats", chatId);
    await updateDoc(messagesRef, messageData);
  };

  const handleFinished = async () => {
    const messageData = {
      finished: true
    };

    const messagesRef = doc(database, "chats", chatId);
    await updateDoc(messagesRef, messageData);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (message) {
      try {
        await sendNewMessage();
        setMessage("");
      } catch (error) {
        console.error("Erro ao enviar mensagem:", error);
      }
    }
  };

  return (
    <C.Container>
      {chat_finished &&
        <span className="span-chat-finished"><i className="fas fa-info-circle"></i> Conversa encerrada por um administrador</span>
      }
      <C.FormAdmin onSubmit={handleSendMessage}>
        <C.Input
          placeholder="Mensagem"
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          required
        />
        <section className="section-send-and-finished-message">
          <C.Input
            placeholder="Imagem"
            onChange={(e) => setImagem(e.target.value)}
            value={imagem}
          />
          <C.Input
            placeholder="url"
            onChange={(e) => setUrl(e.target.value)}
            value={url}
          />
          <C.Send onClick={handleSendMessage}>
            <MdSend />
          </C.Send>
          <C.Send onClick={handleFinished}>
            <FaLock />
          </C.Send>
        </section>
      </C.FormAdmin>
    </C.Container>
  );
};

export default ChatFooter;
