import { useEffect, useState } from 'react'
import ChatBody from './body/index.js'
import ChatFooter from './footer/index.js'
import * as C from './styles.js'
import { AppFirebase } from '../../../../service/firebase.js';
import { deleteDoc, doc, getDoc, getFirestore } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';

export default function Chat( { user, profile, chatId, showChat, IconHelp, IconMovie } ) {
    
    const [ chat, setChat ] = useState();
    const database = getFirestore(AppFirebase);
    const navigate = useNavigate();

    useEffect(()=>{

        const getChat = async ()=>{
            const docRef = doc(database, `chats/${chatId}`);
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) {
                setChat(docSnap.data());
            }
        }

        getChat();
    }, [ chatId ]);
    
    function convertDate(timestamp) {
        const date = new Date(timestamp.seconds * 1000);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hour = date.getHours();
        const minute = date.getMinutes();
      
        return `${padStart(day, 2)}/${padStart(month, 2)}/${year} às ${padStart(hour, 2)}:${padStart(minute, 2)}`;
    }
    
    function padStart(number, length) {
        return `0${number}`.slice(-length);
    }

    const deleteChat = async ()=> {
        const docRef = doc(database, `chats/${chatId}`);
        const docSnap = await deleteDoc(docRef).then(()=>{
            navigate('/chat')
        });
    }
    
    return(
        <C.Container showChat={showChat}>
            {chat &&
                <C.Header>
                    <section>
                        <Link to='/chat' className='back'><i className='fas fa-chevron-left'></i></Link>
                        {chat.img &&
                            <img src={chat.img == 'help' ? IconHelp : chat.img == 'movie' ? IconMovie : chat.img == 'dflix' ? 'https://dflix.netlify.app/imagens/icone%20d-dflix.png' : chat.img} />
                        }
                        <div>
                            {chat.title &&
                                <h4>{chat.title}</h4>
                            }
                            {chat.date &&
                                <span className='date'>{convertDate(chat.date)}</span>
                            }
                        </div>
                    </section>
                    <section>
                        <button className='button-options' onClick={deleteChat}><i className='fas fa-trash'></i></button>
                        <button className='button-options' onClick={()=>window.open(`https://console.firebase.google.com/u/0/project/dflix-f2014/firestore/data/~2Fchats~2F${chat?.id}?hl=pt`, '_blank')}><i className='fas fa-database'></i></button>
                    </section>
                </C.Header>
            }
            <ChatBody user={user} profile={profile} chat={chat}/>
            <ChatFooter user={user} profile={profile} chatId={chatId} chat_finished={chat && chat.finished}/>
        </C.Container>
    )
}