
import { Link } from 'react-router-dom';
import * as  C from './styles.js';

export default function Header() {
    return(
        <C.Container>
            <Link className="back" to="/"><i className="fas fa-chevron-left"></i> Home</Link>
            <Link to="/">
                <img className="logo" src="https://dflix.netlify.app/icones/dflix.svg" alt="logo-dflix" />
            </Link>
        </C.Container>
    )
}