import React from 'react';
import ReactDOM from 'react-dom';
import './global.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './paginas/home';
import Login from './paginas/usuario/login';
  ReactDOM.render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/:screen/" element={<Home/>} />
        <Route path="/:screen/:id/" element={<Home/>} />
      </Routes>
    </BrowserRouter>,
    document.getElementById('app')
  );