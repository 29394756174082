import styled from "styled-components";

export const Container = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    position: relative;

    @media (max-width: 800px) {
        display: ${props => props.showChat ? "block" : "none"};
        width: 100%;
        transition: all .3s ease;
    }
`

export const Header = styled.header `

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background: #202020;

    section {
        display: flex;
        align-items: center;
    }

    .back {
        height: 30px;
        width: 30px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 1.2rem;
        cursor: pointer;
        margin-right: 10px;
        transition: all .3s ease;
    }

    .back:hover {
        background: #353535;
    }

    h4 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    img {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        padding: 5px;
        background: #505050;
        object-fit: cover;
        margin-right: 10px;
    }

    .date {
        font-size: .75rem;
        font-weight: 600;
    }

    .button-options {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border: none;
        font-size: 1.2rem;
        background: none;
        color: #fff;
        border-radius: 50%;
        cursor: pointer;
        transition: all .3s ease;
    }

    .button-options:hover {
        background: #505050;
    }

    @media (max-width: 500px) {
        
        padding: 8px 15px;

        img {
            width: 35px;
            height: 35px;
            border-radius: 5px;
            padding: 5px;
            background: #505050;
            object-fit: cover;
            margin-right: 10px;
        }
        
        h4 {
            font-size: .9rem;
            margin-bottom: -5px;
        }
        
        .date {
            font-size: .7rem;
        }
    }
`