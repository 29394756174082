
import './style.css';

import { AppFirebase, auth, firebase} from '../../service/firebase';
import { useEffect } from 'react';
import { collection, doc, getDoc, getDocs, getFirestore } from 'firebase/firestore';

export default function Usuario( { setUser, verificacaoEmail, getUserInfo, setUserInfo, getMemberInfo, setMemberInfo, setLoadingUser, setLoadingMember } ) {

    useEffect(()=> {

        firebase.auth().onAuthStateChanged(user => {
            if (user) {

                if (setUser) {
                    setUser(user);
                }

                if (verificacaoEmail) {
                    if (!user.emailVerified) {
                        user.sendEmailVerification().then(()=>{})
                    }
                }
                const database = getFirestore(AppFirebase);

                if (getMemberInfo) {
                    const getMemberInfo = async () => {
                        const docRef = doc(database, `members/${user.uid}`);
                        const docSnap = await getDoc(docRef);
                        
                        if (docSnap.exists()) {
                            setMemberInfo(docSnap.data());
                            setLoadingMember(false);
                        } else {
                            setLoadingMember(false);
                        }
                    }

                    getMemberInfo();
                }

                if (getUserInfo) {
                    
                    const getUserInfo = async () => {
                        const docRef = doc(database, `users/${user.uid}`);
                        const docSnap = await getDoc(docRef);
                        
                        if (docSnap.exists()) {
                            setUserInfo(docSnap.data());
                        }
                    }
                    

                    getUserInfo();
                }

                if (setLoadingUser) {
                    setLoadingUser(false);
                }
            } else {
                if (setLoadingUser) {
                    setLoadingUser(false);
                }
                if (setLoadingMember) {
                    setLoadingMember(false);
                }
            }
        })
    }, [])

}