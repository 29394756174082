import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px 20px 20px;
  display: flex;
  background: #202020;
  border-radius: 0 0 5px 5px;
  box-shadow: 2px 1px 3px 1px #0003;

  svg {
    width: 25px;
    height: 25px;
    color: #54656f;
  }

  .span-chat-finished {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: .9rem;
    font-weight: 600;
  }

  @media (max-width: 400px) {

    .span-chat-finished {
      font-size: .8rem;
      padding: 0 5px;
    }

  }
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
`;

export const FormAdmin = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
  margin-top: 10px;

  .section-send-and-finished-message {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .section-send-and-finished-message input {
    margin-right: 10px;
  }

  .section-send-and-finished-message button {
    margin-right: 10px;
  }
`;

export const Input = styled.input`
  padding: 12px 10px;
  outline: none;
  border: none;
  background: #303030;
  color: #fff;
  border-radius: 5px;
  width: 100%;
  box-shadow: inset 0 0 1px 1px #0003;

  &:hover {
    background: #383838;
  }

  &:focus {
    background: #404040;
  }

  &::placeholder {
    color: #ccc;
  }

  &:focus::placeholder {
    color: #fff;
  }
`;

export const Send = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  height: 35px;
  width: 35px;
  border-radius: 2px;
  transition: all .3s ease;

  &:hover {
    background: #353535;
  }

  &:hover svg {
    color: #fff;
  }
`