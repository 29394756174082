import styled from "styled-components";

export const Container = styled.div `
    min-width: 350px;
    max-width: 350px;
    max-height: 100%;
    padding: 20px 10px 10px 15px;
    background: #202020;
    overflow-y: auto;

    h2 {
        margin-bottom: 10px;
    }

    .no-messages {
        font-size: .9rem;
        font-weight: 600;
        margin-top: 50px;
    }

    .admin {
        max-width: 96%;
        margin: 20px 0 5px 0;
    }

    .bt-new-message {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        background: #303030;
    }

    .div-options-chats {
        display: flex;
        border-bottom: 2px solid #353535;
        padding-bottom: 10px;
    }

    .div-options-chats button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 5px;
        font-weight: 600;
        background: #303030;
    }

    .div-options-chats button:last-child {
        margin-right: 0;
    }

    .div-new-message {
        display: none;
        flex-direction: column;
        align-items: center;
        padding: 20px 10px;
    }

    .container-new-message {
        display: none;
        justify-content: center;
        margin: 5px 0 15px 0;
    }

    .new-message {
        padding: 12px;
        width: 100%;
        max-width: 350px;
        border-radius: 50px;
        font-size: .9rem;
        background: #353535;
        color: #fff;
        font-weight: 600;
        cursor: pointer;
        border: none;
        transition: all .3s ease;
    }

    .new-message:hover {
        background: #505050;
    }

    .section-load {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80%;
    }

    @media (max-width: 1200px) {
        width: 350px;
    }

    @media (max-width: 800px) {
        display: ${props => props.showAside ? "block" : "none"};
        min-width: 100%;
        max-width: 100%;
        padding: 20px 5px 10px 15px;
        transition: all .3s ease;

        .div-new-message {
            display: flex;
            margin-top: 50px;
        }

        .no-messages {
            display: none;
        }

        .container-new-message {
            display: flex;
            margin-right: 15px;
        }
    }
`

export const Messages = styled.div `
    max-height: 100%;
    padding: 10px 10px 50px 0;

    h2 {
        margin-bottom: 10px;
    }
`

export const Message = styled.button `
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    background: unset;
    border: none;
    border-radius: 5px;
    margin-bottom: 5px;
    color: ${props => props.new ? '#fff' : '#aaa'};
    cursor: pointer;
    border: 2px solid unset;
    transition: all .3s ease;

    &.active {
        background: #303030;
        border: 2px solid #606060;
    }

    &:hover {
        background: #303030;
    }

    img {
        width: 50px;
        height: 50px;
        object-fit: contain;
        background: #505050;
        padding: 10px;
        border-radius: 5px;
    }

    section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        margin: auto 0 auto 10px;
    }

    div .title {
        font-weight: bold;
        font-size: 1rem;
        text-align: start;
    }

    div .subtitle {
        font-weight: 600;
        font-size: .8rem;
        text-align: start;
    }

    div .title, div .subtitle {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    div i {
        font-size: .6rem;
    }

    @media (max-width: 800px) {
        width: 100%;
        padding: 10px;

        img {
            width: 45px;
            height: 45px;
            padding: 8px;
        }

        div .title {
            font-size: .85rem;
        }

        div .subtitle {
            font-size: .75rem;
        }

        div i {
            font-size: .5rem;
        }

    }
`