import React, { useEffect, useRef, useState } from "react";
import { AppFirebase } from "../../../../../service/firebase";
import * as C from "./styles";
import Message from "./message";
import { collection, doc, getDoc, getFirestore, onSnapshot, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";

import UseAnimations from "react-useanimations";
import loading from 'react-useanimations/lib/loading';
import { useNavigate } from "react-router-dom";

const ChatBody = ({ chat, user, profile }) => {

  const uuid = require('uuid');

  const navigate = useNavigate();

  const database = getFirestore(AppFirebase);
  const [messages, setMessages] = useState([]);
  const [load, setLoad] = useState(true);

  const body = useRef();

  const [ message, setMessage ] = useState('Olá ${user_name}, recebemos seu pedido para #movie_type "#movie_name". #movie_type já está disponível para assistir:');
  const [ imgMessage, setImgMessage ] = useState('');
  const [ url, setUrl ] = useState('/preview/#movie_type/#movie_id');

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(database, `chats/${chat?.id}/messages`),
      (snapshot) => {
        const updatedDocuments = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        const sortedMessages = updatedDocuments.sort((a, b) => {
          return b.date - a.date;
        });
  
        setMessages(sortedMessages.reverse());
        setLoad(false);
        goToEnd();
        
        if (chat?.new_message && (chat?.new_message_by != 'admin')) {
          handleNewMessage();
        }
      }
    );
  
    return () => unsubscribe();
  }, [database, chat]);

  useEffect(() => {
    setLoad(true);
  }, [chat]);
  
  const handleNewMessage = async () => {
    const messageData = {
      new_message: false
    };

    const messagesRef = doc(database, "chats", chat?.id);
    await updateDoc(messagesRef, messageData);
  };

  const goToEnd = () => {
    body.current.scrollTo({
      left: 0,
      top: body.current.scrollHeight,
    });
  };
    
  const newMessage = async (type) => {
    if (!chat?.id) {
      console.error('Chat ID is missing');
      return;
    }
  
    const messageId = uuid.v4();
  
    try {
      const docRef = doc(database, `request_movies/${chat.request_id}`);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log(data);
  
        const messageData = {
          message: data?.movie_requested_name ? data?.movie_requested_name : data?.tv_requested_name,
          img: null,
          url: url.replace('#movie_type', data.movie_requested_type ? 'movie' : 'tv').replace('#movie_id', data.movie_requested_id ? data.movie_requested_id : data.tv_requested_id),
          date: serverTimestamp(),
          account_uid: 'admin',
          profile_uid: 'admin',
          tag: null,
        };
  
        if (type === 'image') {
          messageData.img = imgMessage;
        } else {
          messageData.message = message.replace(/#movie_type/g, data.movie_requested_type ? "o filme" : "a série").replace('#movie_name', data.movie_requested_name ? data.movie_requested_name : data.tv_requested_name);
          messageData.img = null;
          messageData.url = null;
          messageData.tag = '#mensagem automática';
        }
  
        await setDoc(doc(database, "chats", chat.id, 'messages', messageId), messageData).catch((error)=> {
          alert("Erro ao enviar mensagem, veja o erro no console")
          console.log("Erro ao enviar mensagem: " , error)
        });
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <C.Container ref={body}>
      {(!load) && messages?.map((message, key) => (
        <Message
          key={key}
          user={user}
          profile={profile}
          chatId={chat?.id}
          message={{
            id: message?.id,
            tag: message?.tag,
            message: message?.message,
            img: message?.img,
            url: message?.url,
            date: message?.date?.toDate().getTime(),
            profile_uid: message?.profile_uid,
            account_uid: message?.account_uid,
          }}
        />
      ))}
      {load &&
        <section className="section-loading-chat">
            <UseAnimations strokeColor={'#fff'} animation={loading} size={50} />
        </section>
      }
      <div className="authomatized-messages-container">
          <h2>Mensagens Automáticas</h2>
          <div className='form'>
            <input type='text' onChange={(e)=>setMessage(e.target.value)} value={message} placeholder='Message'/>
            <button className='send' onClick={()=>newMessage('')}><i className="fas fa-paper-plane"></i></button>
          </div>
          
          <div className='form'>
            <input type='text' onChange={(e)=>setImgMessage(e.target.value)} value={imgMessage} placeholder='img'/>
            <button className='send' onClick={()=>newMessage('image')}><i className="fas fa-paper-plane"></i></button>
          </div>
      </div>
    </C.Container>
  );
};

export default ChatBody;
