import { useNavigate } from "react-router-dom"
import * as C from "./styles.js"

export default function Dashboard( { vertical, requests, screen } ) {

    const navigate = useNavigate();

    return(
        <C.Container vertical={vertical}>
            {!vertical &&
                <h1>DFLIX <small>Dashboard</small></h1>
            }
            <C.Content vertical={vertical}>
                <C.Button onClick={()=>navigate('/requests')} active={screen == 'requests'} screen={screen}>
                    <i className="fas fa-hand"></i>
                    <span>Pedidos ({requests ? requests?.requests_active?.length : '-'})</span>
                </C.Button>
                <C.Button onClick={()=>navigate('/movie')} active={screen == 'movie'} screen={screen}>
                    <i className="fas fa-film"></i>
                    <span>Filme / Série</span>
                </C.Button>
                <C.Button onClick={()=>navigate('/movieApp')} active={screen == 'movieApp'} screen={screen}>
                    <i className="fas fa-film"></i>
                    <span>Filme / Série - App</span>
                </C.Button>
                <C.Button onClick={()=>navigate('/tv')} active={screen == 'tv'} screen={screen}>
                    <i className="fas fa-tv"></i>
                    <span>Tv</span>
                </C.Button>
                <C.Button onClick={()=>navigate('/member')} active={screen == 'member'} screen={screen}>
                    <i className="fas fa-handshake"></i>
                    <span>Membro</span>
                </C.Button>
                <C.Button onClick={()=>navigate('/chat')} active={screen == 'messages'} screen={screen}>
                    <i className="fas fa-envelope"></i>
                    <span>Mensagens</span>
                </C.Button>
                <C.Button onClick={()=>navigate('/notificationApp')} active={screen == 'notificationApp'} screen={screen}>
                    <i className="fas fa-bell"></i>
                    <span>Notificação App</span>
                </C.Button>
                <C.Button onClick={()=>navigate('/notification')} active={screen == 'notification'} screen={screen}>
                    <i className="fas fa-bell"></i>
                    <span>Notificação</span>
                </C.Button>
            </C.Content>
        </C.Container>
    )
}