import { doc, getFirestore, setDoc, serverTimestamp, getDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { AppFirebase } from "../../service/firebase";
import Usuario from "../usuario";

import * as C from './styles.js';
import { APIKey } from "../../config/key.js";
import { v4 as uuidv4 } from 'uuid';

export default function AddMovie() {
    const inputUrl = useRef();
    const buttonSend = useRef();

    const database = getFirestore(AppFirebase);

    // add movie
    const [name, setName] = useState();
    const [season, setSeason] = useState(1);
    const [episode, setEpisode] = useState(1);
    const [url, setUrl] = useState('https://filemoon.sx/e/');
    const [movieType, setMovieType] = useState('movie');
    const [id, setId] = useState();
    const [idImdb, setIdImdb] = useState();
    const [ageRating, setAgeRating] = useState();
    const [ageRatingDescription, setAgeRatingDescription] = useState();
    const [backdropPath, setBackdropPath] = useState();
    const [player, setPlayer] = useState('free');
    const [nextEpisode, setNextEpisode] = useState(null);
    const [startOpening, setStartOpening] = useState(null);
    const [endOpening, setEndOpening] = useState(null);
    const [movieRecommendedId, setMovieRecommendedId] = useState(null);

    useEffect(() => {
        document.querySelector("meta[name=robots]").setAttribute("content", 'noindex,nofollow');
    }, []);

    async function addTvSerie(e) {
        e.preventDefault();

        await setDoc(doc(database, `watch/${idImdb}`), {
            name: name,
            id: id,
            id_imdb: idImdb,
            date: serverTimestamp(),
            media_type: 'tv',
            backdrop_path: backdropPath,
            age_rating: ageRating,
            age_rating_description: ageRatingDescription,
        }).then(() => {
            alert("Série adicionada");
            newChat();
            inputUrl.current.focus();
        }).catch((erro) => {
            alert(erro)
        });
    }

    async function submit(e) {
        e.preventDefault();

        if (movieType == 'tv' && player == 'dflix') {
            await setDoc(doc(database, `watch/${idImdb}/${season}/${episode}`), {
                player: player,
                src: url,
                age_rating: ageRating,
                age_rating_description: ageRatingDescription,
                show_next: nextEpisode,
                end_opening: endOpening,
                start_opening: startOpening,
                backdrop_path: backdropPath,
                date: serverTimestamp(),
            }).then(() => {
                //alert("Episódio adicionado");
                setEpisode(Number(episode) + 1);
                setUrl('https://filemoon.sx/e/');
                inputUrl.current.focus();
            }).catch((erro) => {
                alert(erro)
            });
        } else if (movieType == 'tv' && player != 'dflix') {
            await setDoc(doc(database, `watch/${idImdb}/${season}/${episode}`), {
                player: player,
                src: url,
                age_rating: ageRating,
                age_rating_description: ageRatingDescription,
                backdrop_path: backdropPath,
                date: serverTimestamp(),
            }).then(() => {
                alert("Episódio adicionado");
                setEpisode(Number(episode) + 1);
                setUrl('https://filemoon.sx/e/');
                inputUrl.current.focus();
            }).catch((erro) => {
                alert(erro)
            });
        } else if (movieType == 'movie') {
            await setDoc(doc(database, `watch/${idImdb}`), {
                name: name,
                player: player,
                src: url,
                age_rating: ageRating,
                age_rating_description: ageRatingDescription,
                movie_recommended_id: movieRecommendedId,
                show_next: nextEpisode,
                backdrop_path: backdropPath,
                date: serverTimestamp(),
                media_type: 'movie',
            }).then(() => {
                newChat();
                setUrl('https://filemoon.sx/e/');
                alert("filme adicionado")
            });
        }
    }

    function getMovie(e) {
        e.preventDefault();
        fetch(`https://api.themoviedb.org/3/${movieType}/${id}?api_key=${APIKey}&vote_count.gte=10&language=pt-BR`)
            .then(Response => Response.json())
            .then(data => {
                setName(data.title ? data.title : data.name);
            });

        fetch(`https://api.themoviedb.org/3/${movieType}/${id}/external_ids?api_key=${APIKey}&language=pt-BR`)
            .then(Response => Response.json())
            .then(data => {
                setIdImdb(data.imdb_id);
            });

        if (movieType == 'tv') {
            fetch(`https://api.themoviedb.org/3/tv/${id}/content_ratings?api_key=${APIKey}`)
                .then(Response => Response.json())
                .then(data => {
                    data?.results?.map((rating) => {
                        if (rating.iso_3166_1 == "BR") {
                            setAgeRating(rating.rating)
                        }
                    })
                });
        } else {
            fetch(`https://api.themoviedb.org/3/movie/${id}/release_dates?api_key=${APIKey}`)
                .then(Response => Response.json())
                .then(data => {
                    data?.results?.map((certification) => {
                        if (certification.iso_3166_1 == "BR") {
                            setAgeRating(certification.release_dates[0].certification);
                        }
                    })
                });
        }
    }

    const newChat = async () => {

        const chatId = uuidv4();

        const docRef = doc(database, `request_movies/${idImdb}`);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();

            await setDoc(doc(database, "chats", chatId), {
                title: `Pedido ${data?.tv_requested_type ? 'série' : 'filme'} "${data.movie_requested_name ? data.movie_requested_name : data.tv_requested_name}"`,
                subtitle: `${data.movie_requested_name ? data.movie_requested_name : data.tv_requested_name} foi adicionado`,
                profile_uid: data.profile_uid,
                account_uid: data.account_uid,
                img: 'dflix',
                id: chatId,
                request_id: idImdb,
                date: serverTimestamp(),
                updated: serverTimestamp(),
                new_message: true,
                new_message_by: 'admin',
                finished: false
            }).then(() => {
                const sendMessage = async () => {
                    await newMessage(data, chatId, '');
                    newMessage(data, chatId, 'image').then(()=>{
                        alert('Mensagem enviada para o usuario');
                    });
                }

                sendMessage();
            });
        } else {
            console.log("O Documento não existe")
        }
    };

    const newMessage = async (data, chatId, type) => {
        if (!chatId) {
            console.error('Chat ID is missing');
            return;
        }

        const messageId = uuidv4();

        try {
            const docRef = doc(database, `request_movies/${idImdb}`);
            const docSnap = await getDoc(docRef);

            const messageData = {
                message: data?.movie_requested_name ? data?.movie_requested_name : data?.tv_requested_name,
                img: null,
                url: `/preview/${data.movie_requested_type ? 'movie' : 'tv'}/${data.movie_requested_id ? data.movie_requested_id : data.tv_requested_id}`,
                date: serverTimestamp(),
                account_uid: 'admin',
                profile_uid: 'admin',
                tag: null,
            };

            if (type === 'image') {
                messageData.img = `https://image.tmdb.org/t/p/w200${backdropPath}`;
            } else {
                messageData.message = 'Olá ${user_name}, recebemos seu pedido para ' + `${data.movie_requested_type ? "o filme" : "a série"}` + ` "${data.movie_requested_name ? data.movie_requested_name : data.tv_requested_name}".` + `${data.movie_requested_type ? " O filme " : " A série "}` + "já está disponível para assistir:";
                messageData.img = null;
                messageData.url = null;
                messageData.tag = '#mensagem automática';
            }

            await setDoc(doc(database, "chats", chatId, 'messages', messageId), messageData).catch((error) => {
                alert("Erro ao enviar mensagem, veja o erro no console")
                console.log("Erro ao enviar mensagem: ", error)
            });
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    return (
        <>
            <C.Container>
                <C.Content>
                    <C.Form method="" action="" onSubmit={submit}>
                        <C.Header>
                            <C.Button active={movieType == 'movie'} onClick={() => setMovieType('movie')}>Filme</C.Button>
                            <C.Button active={movieType == 'tv'} onClick={() => setMovieType('tv')}>Série</C.Button>
                        </C.Header>
                        <C.Header>
                            <C.Button active={player == 'free'} onClick={() => setPlayer('free')}>Free</C.Button>
                            <C.Button active={player == 'dflix'} onClick={() => setPlayer('dflix')}>Dflix</C.Button>
                            <C.Button active={player == 'other'} onClick={() => setPlayer('other')}>Other</C.Button>
                        </C.Header>
                        <div>
                            <C.Input required type='text' placeholder="id" value={id} onChange={(e) => setId(e.target.value)} />
                            <C.Button onClick={getMovie}><i className="fas fa-search"></i></C.Button>
                        </div>
                        <C.Input required type='text' placeholder="Nome" value={name} onChange={(e) => setName(e.target.value)} />
                        <C.Input required type='text' placeholder="idImdb" value={idImdb} onChange={(e) => setIdImdb(e.target.value)} />
                        <C.Input required type='text' placeholder="Classificação indicativa" value={ageRating} onChange={(e) => setAgeRating(e.target.value)} />
                        <C.Input required type='text' placeholder="Descrição da classificação indicativa" value={ageRatingDescription} onChange={(e) => setAgeRatingDescription(e.target.value)} />
                        <C.Input required type='text' placeholder="Imagem de fundo" value={backdropPath} onChange={(e) => setBackdropPath(e.target.value)} />
                        {player == 'dflix' &&
                            <>
                                <C.Input required type='text' placeholder="Inicio da abertura (em segundos)" value={startOpening} onChange={(e) => setStartOpening(e.target.value)} />
                                <C.Input required type='text' placeholder="Fim da abertura (em segundos)" value={endOpening} onChange={(e) => setEndOpening(e.target.value)} />
                                <C.Input required type='text' placeholder="Exibir botão próximo episódio (em segundos)" value={nextEpisode} onChange={(e) => setNextEpisode(e.target.value)} />
                                {movieType == 'movie' &&
                                    <C.Input required type='text' placeholder="Filme recomendado (id do filme)" value={movieRecommendedId} onChange={(e) => setMovieRecommendedId(e.target.value)} />
                                }
                            </>
                        }
                        {movieType == 'tv' &&
                            <>
                                <C.Input required type='text' placeholder="Temporada" value={season} onChange={(e) => setSeason(e.target.value)} />
                                <C.Input required type='text' placeholder="Episodio" value={episode} onChange={(e) => setEpisode(e.target.value)} />
                            </>
                        }
                        <C.Input required ref={inputUrl} type='text' placeholder="Src" value={url} onChange={(e) => setUrl(e.target.value)} />
                        {movieType == 'tv' &&
                            <C.Send onClick={addTvSerie}>Adicionar info série</C.Send>
                        }
                        <C.Send ref={buttonSend}>Enviar</C.Send>
                    </C.Form>
                </C.Content>
            </C.Container>
        </>
    )
}
