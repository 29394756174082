import styled from "styled-components";

export const Container = styled.div `
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .container-iframe {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #00000090;
        z-index: 100;
    }

    .container-iframe button {
        margin-bottom: 10px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        border-radius: 50px;
    }

    iframe {
        width: 90%;
        height: 85%;
        border-radius: 5px;
    }
`

export const Screens = styled.div `
    width: 100%;
    padding: 30px;
`

export const Content = styled.div `
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
`