import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/analytics';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging } from 'firebase/messaging';

export const FirebaseConfig = {
  apiKey: "AIzaSyClKu_F50PmV3tzbbYUOlh2B1pkzdGCkpg",
  authDomain: "dflix-f2014.firebaseapp.com",
  projectId: "dflix-f2014",
  storageBucket: "dflix-f2014.appspot.com",
  messagingSenderId: "1023972387539",
  appId: "1:1023972387539:web:2b217912a1d040942f0226",
  measurementId: "G-L858XH5ZBX"
};

// Initialize Firebase
const AppFirebase = firebase.initializeApp(FirebaseConfig);
const AppMessaging = getMessaging();


// Initialize Analytics
const analytics = getAnalytics(AppFirebase);

export { firebase, AppFirebase, AppMessaging, analytics }