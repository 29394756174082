import { doc, getFirestore, setDoc, serverTimestamp, getDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react"
import { AppFirebase } from "../../service/firebase";
import Usuario from "../usuario";

import * as C from './styles.js'
import { APIKey } from "../../config/key.js";

export default function AddMember() {

    //add membro
    const [ membroUid, setMembroUid ] = useState();
    const [ tipoConta, setTipoConta ] = useState("member");
    const [ tipoContaNome, setTipoContaNome ] = useState('Membro');
    const [ descricao, setDescricao ] = useState('membro DFLIX.');
    const [ memberUntil, setMemberUntil ] = useState();

    useEffect(()=> {
        document.querySelector("meta[name=robots]").setAttribute("content", 'noindex,nofollow');
    }, [ ])

    async function submitMembro(e) {
        e.preventDefault();
        try {
            const docRef = doc(database, `users/${membroUid}/`);
            const docSnap = await getDoc(docRef);
    
            console.log('Chamou submit membro');
    
            if (docSnap.exists()) {
                const userGet = docSnap.data();
                const memberData = {
                    account_type: tipoConta,
                    account_type_name: tipoContaNome,
                    account_description: descricao,
                    account_user_email: userGet.account_email,
                    account_user_name: userGet.account_name,
                    account_member_since: serverTimestamp(),
                    account_member_until: new Date(memberUntil),
                };
    
                await setDoc(doc(database, `members/${membroUid}`), memberData);
                
                alert("Membro adicionado");
            } else {
                console.log('O documento não existe');
            }
        } catch (error) {
            console.error('Erro:', error);
            alert('Ocorreu um erro ao adicionar o membro');
        }
    }
    
    const database = getFirestore(AppFirebase);

    function converterTimestamp(e) {
        var dataHoje = e.slice(6, 11) + '-' + e.slice(3, 5) + "-" + e.slice(0, 2);
        var dataHojeTimestamp = new Date(dataHoje + " 04:00:00");
        const timestampDataHoje = Math.floor(dataHojeTimestamp.getTime());
        return timestampDataHoje;
    }

    return (
        <>
            <C.Container>
            <C.Form method="" action="" onSubmit={submitMembro}>
                <C.Input required type='text' placeholder="uid" value={membroUid} onChange={(e)=>setMembroUid(e.target.value)}/>
                <C.Input required type='text' placeholder="tipo conta, ex: 'member'" value={tipoConta} onChange={(e)=>setTipoConta(e.target.value)}/>
                <C.Input required type='text' placeholder="tipo conta nome, ex: 'Membro PRO'" value={tipoContaNome} onChange={(e)=>setTipoContaNome(e.target.value)}/>
                <C.Input required type='text' placeholder="descricao" value={descricao} onChange={(e)=>setDescricao(e.target.value)}/>
                <C.Input required type='date' placeholder="Membro até" value={memberUntil} onChange={(e)=>setMemberUntil(e.target.value)}/>
                <C.Send>Enviar</C.Send>
            </C.Form>
            </C.Container>
        </>
    )
}