import { useEffect, useState } from 'react';
import * as C from './styles.js'
import { collection, getDocs, getFirestore, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { AppFirebase } from '../../../../service/firebase.js';
import { useNavigate } from 'react-router-dom';
import NewChat from './new-message/index.js';

import UseAnimations from "react-useanimations";
import loading from 'react-useanimations/lib/loading';
import Default from '../default/index.js';
import NewChatAuthomatized from './new-message-authomatized/index.js';

export default function Aside({ profile, account_uid, profile_uid, chatId, showAside, hasChats, setHasChats, IconHelp, IconMovie }) {

    const database = getFirestore(AppFirebase);
    const [ chats, setChats ] = useState();
    const [ newChat, setNewChat ] = useState(false);
    const [ newChatAuthomatized, setNewChatAuthomatized ] = useState(false);
    const [ chatsFinished, setChatsFinished ] = useState(false);
    const [ load, setLoad ] = useState(true);

    const navigate = useNavigate();
    
    useEffect(() => {

      if (account_uid) {
          setLoad(true);
          const chatsRef = query(
            collection(database, `chats`),
            where('finished', '==', chatsFinished),
            orderBy('updated', 'desc')
          );
          const chatsUnsubscribe = onSnapshot(chatsRef, (snapshot) => {
            const updatedDocuments = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setLoad(false);
            setChats(updatedDocuments);
            setHasChats(updatedDocuments.length >= 1 ? true : false);
          }, (error) => {
            console.error('Ocorreu um erro ao solicitar chats:', error);
          });
          return () => chatsUnsubscribe();
        }

    }, [database, account_uid, chatsFinished]);

    return(
        <C.Container showAside={showAside}>
            <h2>Mensagens</h2>
            <div className='admin'>
                <C.Message new={true} className='bt-new-message' onClick={()=>setNewChat(true)}><i class="fas fa-envelope"></i> <span>Novo chat com id</span> <span></span></C.Message>
                <C.Message new={true} className='bt-new-message' onClick={()=>setNewChatAuthomatized(true)}><i class="fas fa-envelope"></i> <span>Chat "automático"</span> <span></span></C.Message>
                <div className='div-options-chats'>
                    <C.Message new={true} onClick={()=>setChatsFinished(false)}>Pendentes {!chatsFinished && <i className="fas fa-check"></i>}</C.Message>
                    <C.Message new={true} onClick={()=>setChatsFinished(true)}>Finalizadas {chatsFinished && <i className="fas fa-check"></i>}</C.Message>
                </div>
            </div>
            {newChat &&
                <NewChat setNewChat={setNewChat} account_uid={account_uid} profile_uid={profile_uid}/>
            }
            {newChatAuthomatized &&
                <NewChatAuthomatized setNewChatAuthomatized={setNewChatAuthomatized}/>
            }
            {load &&
              <section className="section-load">
                  <UseAnimations strokeColor={'#fff'} animation={loading} size={50} />
              </section>
            }
            {(!load && chats?.length == 0) &&
              <div className='div-new-message'>
                <Default profile={profile}/>
              </div>
            }
            {(!load && chats && chats.length >= 1) &&
                <C.Messages>
                    {chats.map((chat, key)=> {
                        return(
                            <C.Message new={chat.new_message && (chat.new_message_by != 'admin')} className={chatId == chat.id && 'active'} key={key} onClick={()=>navigate(`/chat/${chat.id}`)} active={chat.id == chatId}>
                                <img src={chat.img == 'help' ? IconHelp : chat.img == 'movie' ? IconMovie : chat.img == 'dflix' ? 'https://dflix.netlify.app/imagens/icone%20d-dflix.png' : chat.img}/>
                                <section>
                                  <div>
                                    <h4 className='title'>{chat.title}</h4>
                                    <p className='subtitle'>{chat.subtitle}</p>
                                    <span className='subtitle'>{chat.new_message ? 'Não visualizada' : 'Visualizada'}</span>
                                  </div>
                                  {(chat.new_message && chat.new_message_by != 'admin') &&
                                    <div>
                                      <i className='fas fa-circle'></i>
                                    </div>
                                  }
                                </section>
                            </C.Message>
                    )})}
                </C.Messages>
            }
        </C.Container>
    )
}