import { useEffect, useState } from "react";
import Aside from "./componentes/aside";
import ChatBody from "./componentes/chat/index.js";
import * as  C from './styles.js';
import { parseJSON } from "jquery";
import { Link, useParams } from "react-router-dom";
import Default from "./componentes/default/index.js";

import IconHelp from './componentes/icon-help.svg'
import IconMovie from './componentes/icon-movie.svg'
import Header from "./componentes/header/index.js";

export default function Chat({ user }) {
    
    const [ profile, setProfile ] = useState({});
    const [ hasChats, setHasChats ] = useState();
    const { id } = useParams();

    useEffect(()=> {

        const getProfile = parseJSON(localStorage.getItem('profile'));

        if (getProfile && getProfile.profile_uid) {
            setProfile(getProfile);
        }

    }, [ ]);
    
    useEffect(()=> {
        document.querySelector("meta[name=robots]").setAttribute("content", 'noindex,nofollow');
    }, [ ]);

    return (
        <C.Container>
            <Header />
            <C.Content>
                <Aside showAside={!id} profile={profile} account_uid={user.uid} profile_uid={profile.profile_uid} IconHelp={IconHelp} IconMovie={IconMovie} chatId={id} hasChats={hasChats} setHasChats={setHasChats}/>
                {id &&
                    <ChatBody showChat={id} profile={profile} user={user} chatId={id} IconHelp={IconHelp} IconMovie={IconMovie}/>
                }
                {!id &&
                    <div className="div-default-chat-desktop">
                        <Default profile={profile} showAutomaticMessages={true}/>
                    </div>
                }
            </C.Content>
        </C.Container>
    )
}