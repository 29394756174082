import styled from "styled-components";

export const Container = styled.div `
    display: flex;
    align-items: ${props => props.vertical ? 'start' : 'center'};
    justify-content: ${props => props.vertical ? 'start' : 'center'};
    flex-direction: column;
    height: 100vh;
    width: 100%;
    padding: ${props => props.vertical ? '0' : '150px 0'};
    border-right: ${props => props.vertical ? '5px solid #505050' : 'unset'};
    max-width: ${props => props.vertical ? '320px' : 'unset'};
    margin-right: 20px;
    overflow-y: auto;
    overflow-x: hidden;

    h1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 3rem;
    }

    h1 small {
        font-size: .8rem;
    }
`

export const Content = styled.div `
    display: ${props => props.vertical ? 'flex' : 'grid'};
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    align-items: ${props => props.vertical ? 'start' : 'center'};
    justify-content: ${props => props.vertical ? 'start' : 'center'};
    flex-direction: ${props => props.vertical ? 'column' : 'row'};
    min-width: ${props => props.vertical ? '300px' : 'unset'};
    max-width: ${props => props.vertical ? '300px' : 'unset'};
    width: 100%;
    max-width: 650px;
    height: ${props => props.vertical ? '100%' : 'unset'};
    margin: auto;
    padding: 5px 15px 5px 5px;
`

export const Button = styled.button `
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f00;
    border: ${props => props.active ? '2px solid #aaa' : '2px solid transparent'};
    color: #fff;
    padding: 50px;
    width: 100%;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    max-width: 300px;
    opacity: ${props => (!props.screen || props.active) ? '1' : '.5'};

    i {
        font-size: 2rem;
        margin-right: 10px
    }
`