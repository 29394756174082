import styled from "styled-components";

export const Container = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: #18181899;

    @media (max-width: 600px) {
        padding: 10px;
    }
`

export const Content = styled.div `
    background: #000;
    max-width: 800px;
    padding: 20px;
    border-radius: 10px;

    header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    header .close {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        margin-right: 10px;
    }

    .form {
        display: flex;
        flex-direction: column;
    }

    .form input {
        height: 40px;
        padding: 5px;
        width: 400px;
        max-width: 100%;
    }

    .form input::placeholder {
        color: #202020;
    }

    .send {
        height: 40px;
        width: 100%;
        margin-top: 20px;
        background: #f00;
        color: #fff;
        border: none;
        cursor: pointer;
        transition: all .3s ease;
    }

    .send:hover {
        opacity: .7;
    }

    @media (max-width: 600px) {
        max-width: 100%;
    }
`