import styled from "styled-components";

export const Container = styled.div `
    border-radius: 10px;
    overflow: auto;
    max-height: 100%;

    h1 {
        margin: 10px 0 20px 0;
    }

    .span-copied {
        position: fixed;
        bottom: 20px;
        left: 20px;
        background: #fff;
        color: #000;
        padding: 20px;
        border-radius: 5px;
    }
`

export const Request = styled.li `

    padding: 20px 10px;
    list-style: none;

    .status {
        background: ${props => props.status == 'later' ? "red" : props.status == 'finished' ? "green" : "orange"};
        color: ${props => props.status ? "#fff" : "#000"};
        font-weight: 600;
        padding: 5px;
        border-radius: 5px;
        font-size: .8rem;
    }

    h3 {
        font-size: 1rem;
        margin-bottom: 5px;
    }

    .user {
        margin: 10px 0 20px 0;
    }

    p {
        font-size: .9rem;
    }

    .date {
        margin-top: 10px;
    }
    
    .options {
        margin-top: 20px;
    }
    
    .options button {
        padding: 15px 10px;
        transition: all .3s ease;
        border: none;
        cursor: pointer;
        margin-right: 5px;
        font-weight: 600;
        border-radius: 5px;
    }
    
    .options button:hover {
        background: #f00;
        color: #fff;
    }

    .active {
        background: #f00;
        color: #fff;
    }

    &:nth-child(even) {
        background: #303030;
    }

    &:nth-child(odd) {
        background: #404040;
    }
`