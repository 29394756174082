import styled from "styled-components";

export const Container = styled.div`

  .delete-message {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border: none;
        font-size: 1.2rem;
        background: none;
        color: #fff;
        margin-left: 5px;
        border-radius: 50%;
        cursor: pointer;
        transition: all .3s ease;
    }

    .delete-message:hover {
        background: #505050;
    }

`;

export const Line = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;

  &.me {
    > div {
      background-color: #ff0000cc;
      color: #fff;
    }
    justify-content: right;
  }

  &.bot {
    > div {
      background-color: #0082c2;
    }
    justify-content: right;
  }
`;

export const Content = styled.div`
  background-color: #353535;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 1px #ccc;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: 3px;
  max-width: 80%;
  
  .redirect-img {
    position: relative;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    margin: auto;
    margin-bottom: 5px;
    border-radius: 5px;
    font-weight: 600;
    font-size: .8rem;
    overflow: hidden;
    max-width: 250px;
    transition: all .3s ease;
  }

  .redirect-img:hover {
    opacity: .7;
  }
  
  .redirect-img img {
    width: 100%;
    border-radius: 5px;
  }

  .redirect-img .img-description {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    padding: 10px 0 5px 0;
    border-radius: 5px;
    background: linear-gradient(to top, #000000cc, #000000cc, transparent);
  }

  .redirect-img .img-description i {
    margin-right: 5px;
  }
  
`;

export const Message = styled.h3`
  font-size: 14px;
  width: 100%;
  max-width: 700px;
  flex-flow: wrap;
  font-weight: 600;
  margin: 5px 40px 5px 5px;
  word-wrap: break-word;
  overflow-wrap: break-word;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

export const Tag = styled.span`
  font-size: .8rem;
  margin: 5px;
  color: #cccc;
  font-weight: 600;

  i {
    font-size: 1.2rem;
    margin-left: 10px;
  }
`;

export const MessageDate = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  color: #fff;
  font-weight: 600;
  text-align: right;
  height: 15px;
  margin: 0 10px 5px 5px;

  &.other {
    color: #ccc;
  }
  
  @media (max-width: 500px) {
    font-size: 10px;
  }
`;